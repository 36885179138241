import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/button";
import { TextInput } from "@/components/inputs/text-input";
import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { customErrorMap } from "@/utils/custom-error-map";
import { request } from "@/utils/request";
import { useState } from "react";

const formSchema = z.object({
  email: z.string().email().nonempty(),
});

const ForgotPasswordPage = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: { email: "" },
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  const [message, setMessage] = useState<string>();

  const onSubmit = async (data: FieldValues) => {
    const response = await request.post<{ message: string }>("/users/passwords", data);

    if (response.ok && response.data?.message) {
      setMessage(response.data.message);
    }
  };

  return (
    <UnauthenticatedPageLayout
      title="Forgot your password?"
      className="max-w-screen-sm mx-auto border rounded-lg bg-gray-100 p-4 flex flex-col gap-4 items-center"
    >
      <p className="text-center">
        Enter your account email address and we&apos;ll email you a link that
        will help you reset your account password.
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 items-center w-full max-w-md"
      >
        <TextInput
          name="email"
          control={control}
          placeholder="EMAIL"
          autoCapitalize="off"
          endAdornment={
            <div className="flex items-center justify-center bg-gray-200 rounded-r border border-gray-500">
              <FontAwesomeIcon icon="envelope" fixedWidth className="px-3" />
            </div>
          }
        />

        <Button type="submit" className="btn-primary" loading={isSubmitting}>
          Send password reset instructions
        </Button>

        {
          message && (
            <p className="text-center">{message}</p>
          )
        }
      </form>

      <Link to="/sign-in" className="link">
        Sign In
      </Link>
    </UnauthenticatedPageLayout>
  );
};

export default ForgotPasswordPage;
